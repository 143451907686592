import React from 'react';
import Base from '../common/Base';
import LinedTitle from '../common/LinedTitle';

const Profile = () => {
    return (
        <Base header="Matthew&nbsp;Homan" 
            childComponent={
                <div>
                    <LinedTitle title="Matthew&nbsp;Homan"></LinedTitle>
                    <h3 style={{textAlign:"center", color:"#9999"}}>L5 Front End Engineer at Zappos</h3>
                    <br></br>
                    <img 
                        src="../images/mecrowncrop.jpg" 
                        alt="me crown cropped" 
                        style={{
                            filter:"grayscale(100)",
                            width:"100%",
                            maxWidth:"200px", 
                            display:"block", 
                            marginLeft:"auto", 
                            marginRight:"auto"
                        }} />
                    <br></br>
                    <br></br>
                    <div style={{display: "flex", justifyContent: "center", listStyle: "none"}}>    
                        <ul>
                            <li>10+ Years of Professional Software Development Experience</li>
                            <li>Technologies: React, NodeJS, Git, CSS</li> 
                            <li>Location: Florida</li> 
                            <li>Graduated from Univerity of Pittsburgh</li> 
                            <li>Bachelors in Computer Science / Minor in Math</li> 
                        </ul>
                    </div>
                </div>
            }>
        </Base>
    )
}

export default Profile;