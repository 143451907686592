import React from 'react';
import Base from '../common/Base';

import './Binance.scss';

const GUIDE_IMAGE_COUNT = 38;

const Binance = props => {
  let imageGuide = [];
  let stepsIndex = [];
  let { stepsText } = props;
  for(let i = 1; i < GUIDE_IMAGE_COUNT + 1; i++) {
    imageGuide.push(
      <div id={`step${i}`} className="guideContainer">
          <div className="guideTxt">{stepsText[i - 1]}</div>
          <img className="guideImg" key={'imageGuide' + i} src={`../images/binanceguide/${i}.jpg`}/>
      </div>
    )
    stepsIndex.push(
      <a className="guideIndex" href={`#step${i}`}>{i}  </a>
    )
  }
  return (
    <Base
      childComponent={
        <>
          <br/>
          <h1>Binance Guide</h1>
          <br/><br/><br/>
          <h4>Purpose</h4>
          <p>The purpose of this guide is to show you how to purchase BNB, convert it to Smart chain BNB and then how to trade it for any other binance coin using Pancake Swap</p>
          <br/><br/><br/>
          <h4>This Guide Will Use the Following</h4>
          {
            props.icons.map((icon, i) =>    
              <div
                key={'icon' + i}
                style={{
                  height: "auto", minHeight: "80px", minWidth: "150px", verticalAlign: "middle",
                  backgroundSize: "contain", backgroundPosition: "0%40%",
                  backgroundRepeat: "no-repeat", backgroundImage: "url('" + icon + "')",
                  display: "inline-flex"
                }}>
              </div>
            )
          }
          <br/><br/><br/>
          <h4>Helpful Resources</h4>
          <ul>
            <li>
              <a className="guideLink"
                href="https://academy.binance.com/en/articles/connecting-metamask-to-binance-smart-chain"
                target="_blank">Set up the Binance network in MetaMask</a>
            </li>
          </ul>
          <br/><br/><br/>
          <h4>Steps</h4>
          <div>
            {stepsIndex}
          </div>
          <br/>
          {imageGuide}
          <br/>        
        </>
      }>
    </Base>
  )
}

Binance.defaultProps = {
  prereqs: [
    "Crypto.com Account", "Trust Wallet", "Metamask Wallet"
  ],
  icons: [
    "../images/cryptocom.jpg", "../images/pancakeswap.jpg",
    "../images/trustwallet.svg", "../images/metamask.jpg"
  ],
  index: [
    { "Crypto.com BNB Purchase " : "#crypto.com"}
  ],
  stepsText: [
    "1. Open the Crypto.com App, then find and click on the Binance Coin Asset.",
    "2. Click Buy BNB.",
    "3. Select the method to buy the BNB with and the amount of BNB to Buy, Click Buy",
    "4. Verify the details and click Confirm.",
    "5. You should now see the purchase confirmation.",
    "6. Navigate to your account and click on your Crypto Wallet -> Binance Coin Asset.",
    "7. Click on Transfer.",
    "8. Click on Withdraw.",
    "9. Click on External Wallet.",
    "10. Click on the + button to add your Trust Wallet address to send the BNB to.",
    "11. Select Wallet Address.",
    "12. Now header over to your Trust Wallet, Select the BNB asset.",
    "13. Click on Receive.",
    "14. Click on Copy.",
    "15. Head back over to Crypto.com. Paste the address you just " +
         "copied from your Trust Wallet into the BNB Wallet Address Field. " +
         "Check the no memo required box. " +
         "Name the wallet (Trust Wallet BNB), now click Continue.",
    "16. Click OK on the No Memo warning.",
    "17. You will now see your Trust Wallet in the Whitelist, Click on Withdraw BNB.",
    "18. Enter the amount of BNB you wish to send to your Trust Wallet, then click Withdraw.",
    "19. Verify the Trust Wallet Address and the amount of BNB you wish to transfer, Click Confirm.",
    "20. You should now see the Withdrawl Confirmation.",
    "21. Now head back over to your Trust Wallet, You may need to wait some time for the transfer to happen. " +
         "You should eventually see your Transfer, Next click on Swap.",
    "22. Select Swap to Smart Chain, this will convert the BNB to the Binance Chain.",
    "23. Choose the amount you would like to convert then select Swap.",
    "24. Confirm the details.",
    "25. You should now see the Processing Popup, Click the x.",
    "26. After sometime you will now have a Smart Chain (BNB) asset, Click on the asset.",
    "27. Click Send, We will now send the BNB to your MetaMask wallet.",
    "28. Launch your MetaMask wallet and click on your wallet address to copy it.",
    "29. You will see the copy confirmation.",
    "30. Head back over to your Trust Wallet and Click on Paste to paste in your MetaMask wallet address. " + 
        "Select Max or enter the amount of BNB you would like to send to your MetaMask Wallet, click Next.",
    "31. Verify the details and click Confirm to send your BNB.",
    "32. You will now see your Transfer is in progress.",
    "33. After sometime the status will change to Transfer.",
    "34. Head back over to you MetaMask Wallet, You will now see your BNB has transferred.",
    "35. Now we can open PancakeSwap to exchange your BNB for any other binance coin, Open the Menu and click Browser.",
    "36. Click on the Search.",
    "37. Type in Pancakeswap, Click on the result pancakeswap.finance/swap.",
    "38. You will now see your BNB is available to swap. If your wallet does not connect automatically, click on Connect. " +
         "You can now swap your BNB for any coin."
  ]
}

export default Binance;