import React from "react";
import Base from "../common/Base";
import Row from "react-bootstrap/Row";

import "./CardShops.scss";

const CardShops = (props) => {
  return (
    <Base
      childComponent={
        <>
          <br />
          <h1>Card Shops</h1>
          <span style={{ margin: "0 0 0 30px" }}>
            {props.shops.map((shop) => (
              <div>
                <h4>
                  <a
                    style={{ marginRight: "15px", color: "white" }}
                    href={`#${shop.name}`}
                  >
                    {shop.name}
                  </a>
                </h4>
                <div>
                  {shop.websites.map((site) => (
                    <a
                      style={{ marginRight: "15px", color: "dodgerblue" }}
                      href={`#${site.name}`}
                    >
                      {site.name}
                    </a>
                  ))}
                </div>
              </div>
            ))}
          </span>

          <br />
          <br />
          <br />
          {props.shops.map((shop) => (
            <>
              <Row>
                <h2 id={shop.name}>{shop.name}</h2>
              </Row>
              {shop.websites.map((site) => (
                <Row>
                  <h4 id={site.name}>{site.name}</h4>
                  <iframe
                    title={site.name}
                    width="100%"
                    height="800"
                    src={site.url}
                  ></iframe>
                </Row>
              ))}
              <br />
              <br />
              <br />
            </>
          ))}
        </>
      }
    ></Base>
  );
};

CardShops.defaultProps = {
  shops: [
    {
      name: "Pokemon",
      websites: [
        {
          name: "Hills Wholesale Gaming",
          url: "http://wholesalegaming.biz/pokemon/",
        },
        {
          name: "Troll and Toad",
          url: "https://www.trollandtoad.com/pokemon/-sealed-product/3076?Keywords=booster+box&hide-oos=on&min-price=&max-price=&items-pp=60&item-condition=&selected-cat=3076&sort-order=L-H&page-no=1&view=list&subproduct=0&Rarity=&CardType=&minHitPoints=&maxHitPoints=",
        },
        {
          name: "Game Nerdz",
          url: "https://www.gamenerdz.com/pokemon?sort=pricedesc&_bc_fsnf=1&in_stock=1",
        },
        {
          name: "Potomac Dist",
          url: "https://www.potomacdist.com/pokemon-booster-boxes/",
        },
        {
          name: "Pokemon Center",
          url: "https://www.pokemoncenter.com/category/trading-card-game?ps=90&availability=true&sort=sale_price%2Bdesc",
        },
        // { name : "", url : "" },
        // { name : "", url : "" },
        // { name : "", url : "" },
        //{ name : "TCG Best Price", url : "https://www.tcgbestprice.com/collections/all/pokemon?page=1&sort_by=price-descending" },
        // { name : "Pokegram", url : "https://pokegram.net/collections/booster-boxes" },
        // { name : "Noble Knight", url : "https://www.nobleknight.com/Products/Dragon-Ball-Super-Card-Game---Booster-Boxes?PageNumber=1#pf" },
        // { name : "Mini Pokemart", url : "https://minipokemart.com/product/cards" },
        // { name : "Core TCG", url : "https://www.coretcg.com/catalog/t/DragonBallSuper" },
        // { name : "Collectors Cache", url : "https://www.collectorscache.com/catalog/pokemon_sealed_products-pokemon_booster_boxes/386?filter%5B8843%5D=&filter%5B8853%5D=&sort_by_price=1&filtered=1&filter_by_stock=in-stock" },
        // { name : "Prodigy Games", url : "http://www.prodigygamestcg.com/collections/pokemon/availability_in-stock" }
      ],
    },
    {
      name: "Dragon Ball Super",
      websites: [
        {
          name: "Hills Wholesale Gaming",
          url: "https://wholesalegaming.com/dbsuper/",
        },
        {
          name: "Troll and Toad",
          url: "https://www.trollandtoad.com/dragon-ball-super-tcg/-sealed-product/10901?Keywords=booster+box&hide-oos=on&min-price=&max-price=&items-pp=60&item-condition=&selected-cat=10901&sort-order=L-H&page-no=1&view=list&subproduct=0",
        },
        {
          name: "Game Nerdz",
          url: "https://www.gamenerdz.com/dragon-ball-super?sort=priceasc&page=1",
        },
        {
          name: "Potomac Dist",
          url: "http://www.potomacdist.com/list.asp?wordcategory=dragon-ball-super-tcg-booster-boxes",
        },
        {
          name: "Collector Store",
          url: "https://collectorstore.com/games/dragon-ball-super/boxes/",
        },
        // { name : "", url : "" },
        // { name : "", url : "" }
        // { name : "", url : "" }
        // { name : "Gaming Giant", url : "https://www.gaming-giant.com/a/search/dragon-ball-super?filter_availability=in-stock" },
        // { name : "Miniature Market", url : "https://www.miniaturemarket.com/card-games/dragon-ball.html?sort=price+asc&filter[]=stock_status_uFilter:%22In%20Stock%22&sort=price%20asc" },
        // { name : "Prodigy Games", url : "http://www.prodigygamestcg.com/collections/dragonball-super/availability_in-stock" }
      ],
    },
  ],
};

export default CardShops;
